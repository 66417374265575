<template>
  <CRow>
    <CCol col="12" lg="12">
      <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>
        {{ err_msg }}
      </CAlert>
      <CCard>
        <CCardHeader>
          <strong>{{ $lang.post.crud.create }}</strong>
        </CCardHeader>
        <CForm novalidate @submit.prevent="onSubmit">
          <CCardBody>
            <CRow>
              <CCol md="6">
                <div class="form-group" :class="{ 'form-group--error': '' }">
                  <label class="form__label"
                  >{{ $lang.post.form.postType }}
                    <required_span/>
                  </label>
                  <v-select :options="postTypeList" v-model="post.postType">
                  </v-select>
                  <small
                      class="error"
                      v-if="$v.post.postType.$error && !$v.post.postType.required"
                  >
                    {{ $lang.post.validation.required.postType }}
                  </small>
                </div>
              </CCol>
              <CCol md="6">
                <div
                    class="form-group"
                    :class="{ 'form-group--error': $v.post.description.$error }"
                >
                  <label class="form__label"
                  >{{ $lang.post.form.description }}
                    <required_span/>
                  </label>
                  <textarea
                      :placeholder="$lang.post.form.description"
                      class="form-control"
                      v-model="post.description"
                  />
                  <small
                      class="error"
                      v-if="
                      $v.post.description.$error &&
                      !$v.post.description.required
                    "
                  >{{ $lang.post.validation.required.description }}</small
                  >
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group" :class="{ 'form-group--error': '' }">
                  <label class="form__label"
                  >{{ $lang.post.form.industry }}
                    <required_span/>
                  </label>
                  <v-select
                      :options="industryOptions"
                      v-model="post.industry"
                      @option:selected="getSubIndustry"
                  >
                  </v-select>
                  <small
                      class="error"
                      v-if="$v.post.industry.$error && !$v.post.industry.required"
                  >
                    {{ $lang.post.validation.required.industry }}
                  </small>
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group" :class="{ 'form-group--error': '' }">
                  <label class="form__label"
                  >{{ $lang.post.form.subIndustry }}
                    <required_span/>
                  </label>
                  <v-select
                      :options="subIndustryOptions"
                      v-model="post.subIndustry"
                  ></v-select>
                  <small
                      class="error"
                      v-if="
                      $v.post.subIndustry.$error &&
                      !$v.post.subIndustry.required
                    "
                  >
                    {{ $lang.post.validation.required.subIndustry }}
                  </small>
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group" :class="{ 'form-group--error': '' }">
                  <label class="form__label"
                  >{{ $lang.post.form.resourceType }}
                    <required_span/>
                  </label>
                  <v-select
                      :options="resourceTypeOptions"
                      v-model="post.resourceType"
                  ></v-select>
                  <small
                      class="error"
                      v-if="
                      $v.post.resourceType.$error &&
                      !$v.post.resourceType.required
                    "
                  >
                    {{ $lang.post.validation.required.resourceType }}
                  </small>
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group" :class="{ 'form-group--error': '' }">
                  <label class="form__label"
                  >{{ $lang.post.form.duration }}
                    <required_span/>
                  </label>
                  <v-select
                      :options="durationOptions"
                      v-model="post.duration"
                  ></v-select>
                  <small
                      class="error"
                      v-if="$v.post.duration.$error && !$v.post.duration.required"
                  >
                    {{ $lang.post.validation.required.duration }}
                  </small>
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group" :class="{ 'form-group--error': '' }">
                  <label class="form__label"
                  >{{ $lang.post.form.currency }}
                    <required_span/>
                  </label>
                  <v-select
                      :options="currencyOptions"
                      v-model="post.currency"
                  ></v-select>
                  <small
                      class="error"
                      v-if="$v.post.currency.$error && !$v.post.currency.required"
                  >
                    {{ $lang.post.validation.required.currency }}
                  </small>
                </div>
              </CCol>
              <CCol md="6">
                <div
                    class="form-group"
                    :class="{ 'form-group--error': $v.post.budget.$error }"
                >
                  <label class="form__label"
                  >{{ $lang.post.form.budget }}
                    <required_span/>
                  </label>
                  <input
                      :placeholder="$lang.post.form.budget"
                      class="form-control"
                      v-model="post.budget"
                  />
                  <small
                      class="error"
                      v-if="$v.post.budget.$error && !$v.post.budget.required"
                  >
                    {{ $lang.post.validation.required.budget }}</small
                  >
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group" :class="{ 'form-group--error': '' }">
                  <label class="form__label"
                  >{{ $lang.post.form.priority }}
                    <required_span/>
                  </label>
                  <v-select
                      :options="priorityOptions"
                      v-model="post.priority"
                  ></v-select>
                  <small
                      class="error"
                      v-if="$v.post.priority.$error && !$v.post.priority.required"
                  >
                    {{ $lang.post.validation.required.priority }}
                  </small>
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group" :class="{ 'form-group--error': '' }">
                  <label class="form__label"
                  >{{ $lang.post.form.workType }}
                    <required_span/>
                  </label>
                  <v-select
                      :options="workTypeOptions"
                      v-model="post.workType"
                  ></v-select>
                  <small
                      class="error"
                      v-if="$v.post.workType.$error && !$v.post.workType.required"
                  >
                    {{ $lang.post.validation.required.workType }}
                  </small>
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group" :class="{ 'form-group--error': '' }">
                  <label class="form__label"
                  >{{ $lang.post.form.user }}
                    <required_span/>
                  </label>
                  <v-select
                      :options="userOptions"
                      v-model="post.user"
                  ></v-select>
                  <small
                      class="error"
                      v-if="$v.post.user.$error && !$v.post.user.required"
                  >
                    {{ $lang.post.validation.required.user }}
                  </small>
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group" :class="{ 'form-group--error': '' }">
                  <label class="form__label"
                  >{{ $lang.common.form.isActive }}
                    <required_span/>
                  </label
                  >&nbsp;&nbsp;
                  <input
                      type="checkbox"
                      :placeholder="$lang.common.form.isActive"
                      :value="1"
                      @change="changedIsActive"
                      v-model="post.isActive"
                  />
                </div>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton
                type="submit"
                size="sm"
                color="primary"
                :disabled="submitted"
            >
              <CIcon name="cil-check-circle"/>
              {{ $lang.buttons.general.crud.submit }}
            </CButton>
            <CButton color="danger" size="sm" @click="cancel">
              <CIcon name="cil-ban"/>
              {{ $lang.buttons.general.crud.cancel }}
            </CButton>
          </CCardFooter>
        </CForm>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {
  posts,
  industry,
  resourcetype,
  duration,
  currencytype,
  priority,
  worktype,
  users,
  skills,
} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import {required} from "vuelidate/lib/validators";
import required_span from "../../components/layouts/general/required-span";
import VueToasted from "vue-toasted";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueToasted);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);

export default {
  name: "PostCreate",
  mixins: [Mixin],
  components: {
    required_span,
  },
  data() {
    return {
      submitted: false,
      err_msg: "",
      dismissCountDown: 0,
      postTypeList: [],
      industryOptions: [],
      subIndustryOptions: [],
      resourceTypeOptions: [],
      currencyOptions: [],
      durationOptions: [],
      priorityOptions: [],
      workTypeOptions: [],
      skillsOptions: [],
      userOptions: [],
      self: this,
      module: posts,
      moduleIndustry: industry,
      moduleResource: resourcetype,
      moduleDuration: duration,
      moduleCurrency: currencytype,
      modulePriority: priority,
      moduleWorkType: worktype,
      moduleSkills: skills,
      moduleUser: users,
      post: {
        description: "",
        postType: "",
        subIndustry: "",
        resourceType: "",
        currency: "",
        priority: "",
        duration: "",
        industry: "",
        isActive: 1,
        workType: "",
        budget: "",
        user: "",
        skills: "",
      },
    };
  },
  validations: {
    post: {
      description: {
        required,
      },
      postType: {
        required,
      },
      industry: {
        required,
      },
      subIndustry: {
        required,
      },
      resourceType: {
        required,
      },
      duration: {
        required,
      },
      currency: {
        required,
      },
      priority: {
        required,
      },
      workType: {
        required,
      },
      budget: {
        required,
      },
      user: {
        required,
      },
    },
  },
  mounted() {
    let self = this;

    store.commit("showLoader", false); // Loader Off

    self.postTypeList.push(
        {value: "1", label: "Required"},
        {value: "2", label: "Available"}
    );
    self.dismissCountDown = 0;
    axios.get(this.listUrlApi(this.moduleIndustry)).then((response) => {
      response.data.data.map(function (value, key) {
        if (value.industryId === "") {
          self.industryOptions.push({value: value.id, label: value.industry});
        }
      });
    });

    axios.get(this.listUrlApi(this.moduleResource)).then((response) => {
      response.data.data.map(function (value, key) {
        self.resourceTypeOptions.push({value: value.id, label: value.title});
      });
    });

    axios.get(this.listUrlApi(this.moduleDuration)).then((response) => {
      response.data.data.map(function (value, key) {
        self.durationOptions.push({value: value.id, label: value.title});
      });
    });
    axios.get(this.listUrlApi(this.moduleCurrency)).then((response) => {
      response.data.data.map(function (value, key) {
        self.currencyOptions.push({value: value.id, label: value.title});
      });
    });

    axios.get(this.listUrlApi(this.modulePriority)).then((response) => {
      response.data.data.map(function (value, key) {
        self.priorityOptions.push({value: value.id, label: value.title});
      });
    });

    axios.get(this.listUrlApi(this.moduleWorkType)).then((response) => {
      response.data.data.map(function (value, key) {
        self.workTypeOptions.push({value: value.id, label: value.title});
      });
    });

    axios.get(this.listUrlApi(this.moduleUser)).then((response) => {
      response.data.map(function (value, key) {
        self.userOptions.push({value: value.id, label: value.username});
      });
    });
  },
  methods: {
    changedIsActive(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.post.isActive = 1;
      } else {
        self.post.isActive = 0;
      }
    },

    getSubIndustry() {
      let self = this;
      if (self.post.industry.value > 0) {
        const postData = {industry_id: self.post.industry.value};
        axios
            .post(this.listUrlApi(this.moduleIndustry), postData)
            .then((response) => {
              self.subIndustryOptions = [];
              response.data.data.map(function (value, key) {
                if (value.industryId > 0) {
                  self.subIndustryOptions.push({
                    value: value.id,
                    label: value.SubIndustry,
                  });
                }
              });
            });
      }
    },

    sendAlert() {
      this.$root.$emit("alert", "test1", "test2");
    },
    onSubmit() {
      let self = this;
      this.$v.post.$touch();
      if (this.$v.post.$invalid) {
        self.submitted = false; //Enable Button
        this.submitStatus = "ERROR";
      } else {
        self.submitted = true; //Disable Button
        this.submitStatus = "PENDING";
        setTimeout(() => {
          let self = this;
          const postData = {
            industry: self.post.industry.value,
            description: self.post.description,
            resourceType: self.post.resourceType.value,
            duration: self.post.duration.value,
            currency: self.post.currency.value,
            budget: self.post.budget,
            priority: self.post.priority.value,
            workType: self.post.workType.value,
            postType: self.post.postType.value,
            subindustry: self.post.subIndustry.value,
            isActive: self.post.isActive,
            userId: self.post.user.value,
          };
          axios
              .post(this.createUrlWeb(this.module), postData)
              .then(function (response) {
                self.submitted = false; //Enable Button
                if (response.data.code === 200) {
                  localStorage.setItem("showAlert", "This is my alert");
                  self.sendAlert();
                  self.$router.push({path: "/posts"});
                } else {
                  self.err_msg = response.data.message;
                  self.dismissCountDown = 10;
                }
              })
              .catch(function (error) {
                self.submitted = false; //Enable Button
              });
        }, 500);
      }
    },
    cancel() {
      this.usersOpened
          ? this.$router.go(-1)
          : this.$router.push({path: "/country"});
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
